import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import ArrowRight from "../components/svg/arrowRight"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Hero from "../components/hero"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import Testimonial from "../components/testimonial"
import Cta from "../components/cta"

const HalogenPage = () => (
    <Layout>
        <Seo 
            title="Australian healthtech startup development case study" 
            description="Healthtech Startup case study - the development services completed for this Australian health technology cloud-based startup."
        />

        <Hero
            h1="Healthtech startup development case study"  
            h2="Halogen Health"
        />

        <section className="bg-noise halogen section-pad-sm">
            <div className="container-lg" id="anchor">
                <div className="row">
                    <StaticImage 
                        src="../images/halogen-main.png"
                        alt="Screens of the Halogen Health website and web app"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        className="porti-top-img"
                        placeholder="none"
                        backgroundcolor="white"
                        layout="fullWidth"
                        quality="100%"
                    />
                </div>
            </div>
        </section>

        <section className="section-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="porti-wrapper">
                        <div className="cols">
                            <div className="col-26">
                                <div className="sticky-porti-menu">
                                    <button onClick={() => scrollTo('#bg')} className="btn">
                                        Background
                                    </button>
                                    <button onClick={() => scrollTo('#services')} className="btn">
                                        Services
                                    </button>
                                    <button onClick={() => scrollTo('#work')} className="btn">
                                        The work
                                    </button>
                                    <button onClick={() => scrollTo('#testimonial')} className="btn">
                                        Testimonial
                                    </button>
                                    <button onClick={() => scrollTo('#project')} className="btn">
                                        View project
                                    </button>
                                </div>
                            </div>
                            <div className="col-67 long-text">
                                <div id="bg" className="porti-text-section">
                                    <h3>Background</h3>
                                    <p>Halogen Health is a healthtech startup that helps Australians proactively manage their health. Via lifestyle and genetics data, Halogen helps identify potential health risks early.</p>
                                </div>
                                <div id="services" className="porti-text-section">
                                    <h4>Services</h4>
                                    <Link to="/web-design/" className="btn btn-lg btn-line">Web design</Link>
                                    <Link to="/react-developers-sydney-canberra/" className="btn btn-lg btn-line">React development</Link>
                                    <Link to="/django-developer-sydney-canberra/" className="btn btn-lg btn-line">Python / Django development</Link>
                                    <Link to="/aws-infrastructure/" className="btn btn-lg btn-line">AWS infrastructure</Link>
                                </div>
                                <div id="work" className="porti-text-section">
                                    <h4>The work</h4>
                                    <p>Launch Lab designed the website and developed the web application using React and Python / Django.</p>
                                    <p>API integration work included Typeform, Intercom, Stripe and other marketing focussed APIs.</p>
                                </div>
                                <div id="testimonial" className="porti-text-section">
                                    <h4>Testimonial</h4>
                                    <Testimonial
                                        excerpt="Pleased every step of the way"
                                        quote="“If you're a founder with an idea, Launch Lab should be the first place you visit. Bringing your idea to life is what they do. And it's all they do. No accountants, marketing people, secretaries. Every single dollar goes to your project. We considered several Sydney web development houses before settling on Launch Lab, and have been pleased every step of the way.”"
                                        name="George Racz"
                                        title="CEO, Halogen Health"
                                    />
                                </div>
                                <div id="project" className="porti-text-section">
                                    <h5>View project</h5>
                                    <p>View the Halogen Health website.</p>
                                    <a href="https://halogenhealth.com.au/" className="btn btn-lg btn-purple">View website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>
        
        <Cta 
            text="Get an obligation free web design or web development quote from an experienced local Australian development team."
        />
    </Layout>
)

export default HalogenPage